<script setup lang="ts">
import { computed } from 'vue'
import { locale } from '@/i18n'
import SwiperBlockContent from '@/components/SwiperBlockContent.vue'

defineProps<{
   column: number
}>()

const list = computed(() => {
   if (locale.value === 'ru') {
      return [
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/69fdc98db4422f53291224f83fa11b1f/watch',
            name: 'Иван',
            age: '18 лет',
            city: 'Саратов',
            profession: 'Студент'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/e079cf350c5731ed22872a02313f4b59/watch',
            name: 'Тигран',
            age: '19 лет',
            city: 'Самара',
            profession: 'SMM'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/fc5826687e6d1156987fd18484da91a2/watch',
            name: 'Полина',
            age: '28 лет',
            city: 'Москва',
            profession: 'SMM'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/fd3d34989e32b4d4b35a3f416bf6c7c5/watch',
            name: 'Тимур',
            age: '35 лет',
            city: 'Екатеринбург',
            profession: 'Инженер'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/8a8a1f0f8caf1bf357743314cb27fe51/watch',
            name: 'Элина',
            age: '27 лет',
            city: 'Москва',
            profession: 'Бизнес-Ассистент'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/20be696168fbd67b91e8385ad0f288f6/watch',
            name: 'Сергей',
            age: '32 года',
            city: 'Санкт-Петербург',
            profession: 'Охранник'
         }
      ]
   } else {
      return [
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/f9eac52271a9a2c000f7a9b0539e660f/watch',
            name: 'Levan',
            age: '28 years',
            city: 'Georgia',
            profession: 'Affiliate marketing'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/f5a612fecd7f9049c075781de1a232b1/watch',
            name: 'Elina',
            age: '27 years',
            city: 'Moscow',
            profession: 'Business assistant'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/89a98cce7950e71ad1c5d4a201943804/watch',
            name: 'Tigran',
            age: '19 years',
            city: 'Samara',
            profession: 'SMM'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/4a2f801e167a351322d0f006e7bc77fe/watch',
            name: 'Ivan',
            age: '18 years',
            city: 'Saratov',
            profession: 'Student'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/e9da891e8daf876c46c8ed9a6bd76463/watch',
            name: 'Polina',
            age: '28 years',
            city: 'Moscow',
            profession: 'SMM'
         },
         {
            link: 'https://customer-ksmejglgjfbn3ydn.cloudflarestream.com/cdbf326cd7ed2231f7fdc48abd7485f0/watch',
            name: 'Sergey',
            age: '32 years',
            city: 'Saint Petersburg',
            profession: 'Security'
         }
      ]
   }
})
</script>

<template>
   <SwiperBlockContent :column="column" :title="$t('about.testimonials.title')" :total="6">
      <div
         v-for="(review, index) in list"
         :key="index"
         class="flex-shrink-0 <laptop:w-292px! h-519px flex-shrink-0 rounded-10px overflow-hidden relative flex flex-col justify-end p-4px <laptop:snap-start"
         :style="`width: calc(${100 / column}% - 18px)`"
      >
         <iframe
            height="519"
            :src="review.link"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="absolute inset-0 w-full"
            loading="lazy"
         ></iframe>

         <div class="h-54px rounded-10px bg-black-850/20 shadow-black flex flex-col items-center justify-center relative z-1">
            <div class="pb-8px s-medium">{{ review.name }}</div>
            <div class="xs-medium">{{ review.age }} • {{ review.city }} • {{ review.profession }}</div>

            <div class="absolute inset-0 bg-gray-50/4 border-2 border-gray-50/2 backdrop-blur-25 rounded-10px -z-1">
               <div class="bg-black-850/20 w-full h-full rounded-inherit relative"></div>
            </div>
         </div>
      </div>
   </SwiperBlockContent>
</template>

<style scoped></style>
